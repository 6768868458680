import type { ACTIVE_SIDEBAR_TYPE } from '~/types/enums'
import { SIDEBAR_STATE } from '~/types/enums'

export type SidebarOpenClosed = SIDEBAR_STATE.OPEN | SIDEBAR_STATE.CLOSED

export const useSidebarStore = defineStore('sidebar', () => {
  const isGlobalSidebarOpen = ref(false)
  const isMainMenuOpen = ref(false)
  const isComponentSidebarOpen = ref(false)

  const delayedGlobalSidebarState = ref<SidebarOpenClosed>(SIDEBAR_STATE.CLOSED)
  const delayedComponentSidebarState = ref<SidebarOpenClosed>(SIDEBAR_STATE.CLOSED)

  const currentGlobalSidebarType = ref<ACTIVE_SIDEBAR_TYPE>()
  const currentComponentSidebarType = ref<ACTIVE_SIDEBAR_TYPE>()

  let timeoutId: number | undefined

  const openGlobalSidebar = (sidebarType: ACTIVE_SIDEBAR_TYPE) => {
    isGlobalSidebarOpen.value = true

    currentGlobalSidebarType.value = sidebarType
  }

  const openMainMenu = () => {
    isMainMenuOpen.value = true
  }

  const openComponentSidebar = (sidebarType: ACTIVE_SIDEBAR_TYPE) => {
    isComponentSidebarOpen.value = true

    currentComponentSidebarType.value = sidebarType
  }
  const closeMainMenu = () => {
    isMainMenuOpen.value = false
  }

  const closeSidebar = () => {
    isGlobalSidebarOpen.value = false
    isComponentSidebarOpen.value = false
    isMainMenuOpen.value = false
  }

  const toggleGlobalSidebar = () => {
    isGlobalSidebarOpen.value = !isGlobalSidebarOpen.value
  }
  const toggleMainMenu = () => {
    isMainMenuOpen.value = !isMainMenuOpen.value
  }

  const toggleComponentSidebar = () => {
    isComponentSidebarOpen.value = !isComponentSidebarOpen.value
  }

  const globalSidebarState = computed<SidebarOpenClosed>(() => {
    return isGlobalSidebarOpen.value ? SIDEBAR_STATE.OPEN : SIDEBAR_STATE.CLOSED
  })

  const componentSidebarState = computed<SidebarOpenClosed>(() => {
    return isComponentSidebarOpen.value ? SIDEBAR_STATE.OPEN : SIDEBAR_STATE.CLOSED
  })

  const forcecloseSidebar = () => {
    isGlobalSidebarOpen.value = false
    isComponentSidebarOpen.value = false
    currentGlobalSidebarType.value = undefined
    currentComponentSidebarType.value = undefined
  }

  watch(
    () => globalSidebarState.value,
    newValue => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      timeoutId = setTimeout(() => {
        delayedGlobalSidebarState.value = newValue

        if (newValue === SIDEBAR_STATE.CLOSED) {
          currentGlobalSidebarType.value = undefined
        }
      }, 300) as unknown as number
    },
  )

  watch(
    () => componentSidebarState.value,
    newValue => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      timeoutId = setTimeout(() => {
        delayedComponentSidebarState.value = newValue

        if (newValue === SIDEBAR_STATE.CLOSED) {
          currentComponentSidebarType.value = undefined
        }
      }, 300) as unknown as number
    },
  )

  return {
    isGlobalSidebarOpen,
    isMainMenuOpen,
    isComponentSidebarOpen,
    currentGlobalSidebarType,
    currentComponentSidebarType,
    openGlobalSidebar,
    openMainMenu,
    closeSidebar,
    closeMainMenu,
    toggleGlobalSidebar,
    toggleMainMenu,
    forcecloseSidebar,
    openComponentSidebar,
    toggleComponentSidebar,
    globalSidebarOpenClosedState: delayedGlobalSidebarState,
    componentSidebarOpenClosedState: delayedComponentSidebarState,
  }
})
